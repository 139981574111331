.container {
  padding: 24px 32px;
  background-color: #f5f6fa;
  min-height: calc(100vh - 60px);
  width: 100%;
}

.syncInfo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 16px;
}

.syncBtn {
  height: 36px;
  padding: 0 20px;
  background-color: #1a237e;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.2s;
  min-width: 80px;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover:not(:disabled) {
    background-color: darken(#1a237e, 5%);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &.syncing {
    .syncIcon {
      animation: spin 1s linear infinite;
    }
  }
}

.syncStatus {
  display: flex;
  align-items: center;
  gap: 16px;

  span {
    font-size: 13px;
    white-space: nowrap;
    
    &.syncSuccess {
      color: #2e7d32;
    }
    
    &.syncError {
      color: #d32f2f;
    }
  }

  .syncTime {
    color: #6B7280;
    opacity: 0.7;
  }
}

.loadingWrapper {
  padding: 48px;
  text-align: center;
  color: #2c3e50;
  
  .loader {
    border: 3px solid #e0e0e0;
    border-top: 3px solid #1a237e;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin: 0 auto 16px;
  }
}

.tableWrapper {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  padding: 16px;
  overflow-x: auto;
}

.dataTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  min-width: 1140px;
  
  th {
    background-color: #f8f9fa;
    color: #2c3e50;
    font-size: 13px;
    padding: 12px 16px;
    font-weight: 500;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    position: relative;

    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }

  td {
    font-size: 13px;
    padding: 12px 16px;
    border-bottom: 1px solid #e0e0e0;
    color: #2c3e50;
  }

  tbody tr:hover {
    background-color: rgba(26, 35, 126, 0.02);
  }
}

.memoButton {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }
}

.hasMemo {
  font-size: 20px;
  color: #1a237e;
}

.noMemo {
  font-size: 20px;
  color: #9e9e9e;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.adGroupRow {
  cursor: pointer;

  &:hover {
    background-color: rgba(26, 35, 126, 0.02);
  }

  &.expanded {
    background-color: rgba(26, 35, 126, 0.05);
  }
}

.adsContainer {
  padding: 16px;
  background-color: #f8f9fa;
}

.adsTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  
  th {
    background-color: #ffffff;
    color: #2c3e50;
    font-size: 12px;
    padding: 8px 12px;
    font-weight: 500;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
  }

  td {
    font-size: 12px;
    padding: 8px 12px;
    border-bottom: 1px solid #e0e0e0;
    background-color: #ffffff;
  }

  tbody tr:hover {
    background-color: rgba(26, 35, 126, 0.02);
  }
}

.noData {
  text-align: center;
  padding: 24px;
  color: #637381;
  font-size: 14px;
  background-color: #ffffff;
  border-radius: 4px;
} 