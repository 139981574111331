$background-color: #F5F6F8;
$text-color: #1A1A1A;
$white: #FFFFFF;
$border-color: #E5E7EB;
$primary-color: #4A72FF;
$gray-color: #6B7280;
$success-color: #2e7d32;

.container {
  padding: 24px 32px;
  background-color: $background-color;
  min-height: calc(100vh - 60px);
  width: 100%;
}

.pageHeader {
  margin-bottom: 24px;

  h1 {
    font-size: 20px;
    font-weight: 600;
    color: $text-color;
    margin-bottom: 8px;
  }

  .pageDescription {
    font-size: 13px;
    color: $gray-color;
  }
}

.searchSection {
  background-color: $white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  padding: 20px;
  margin-bottom: 24px;
}

.searchContainer {
  h2 {
    font-size: 15px;
    font-weight: 600;
    color: $text-color;
    margin-bottom: 16px;
  }
}

.searchGroup {
  .flexContainer {
    display: flex;
    gap: 16px;
    align-items: flex-start;
  }
}

.searchItem {
  display: flex;
  flex-direction: column;
  gap: 6px;
  min-height: 80px;

  label {
    font-size: 12px;
    font-weight: 500;
    color: $gray-color;
  }

  .dateError {
    color: #EF4444;
    font-size: 12px;
    margin-top: 4px;
    white-space: nowrap;
  }

  select, input {
    height: 36px;
    padding: 0 12px;
    border: 1px solid $border-color;
    border-radius: 8px;
    font-size: 13px;
    min-width: 200px;
    color: $text-color;
    background-color: $white;

    &:focus {
      outline: none;
      border-color: $primary-color;
      box-shadow: 0 0 0 2px rgba(74, 114, 255, 0.1);
    }

    &:hover {
      border-color: darken($border-color, 8%);
    }
  }
}

.dateContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dateSeparator {
  color: $gray-color;
  font-size: 13px;
}

.searchButton {
  height: 36px;
  padding: 0 20px;
  background-color: $primary-color;
  color: $white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.2s;
  margin-top: 24px;
  min-width: 80px;
  white-space: nowrap;

  &:hover {
    background-color: darken($primary-color, 5%);
  }

  &:active {
    transform: scale(0.98);
  }
}

.dataSection {
  background-color: $white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  overflow: auto;
}

.tableWrapper {
  overflow-x: auto;
  padding: 1px;
}

.table, .subTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  th, td {
    padding: 12px 16px;
    text-align: left;
    font-size: 13px;
    border-bottom: 1px solid $border-color;
  }

  th {
    background-color: $background-color;
    color: $gray-color;
    font-weight: 500;
    white-space: nowrap;
    position: sticky;
    top: 0;
    z-index: 1;

    &.sortable {
      cursor: pointer;
      user-select: none;
      transition: color 0.2s;

      &:hover {
        color: $text-color;
      }
    }
  }

  td {
    color: $text-color;
  }

  tbody tr {
    transition: background-color 0.15s;

    &:hover {
      background-color: rgba($primary-color, 0.02);
    }
  }
}

.loadingWrapper {
  padding: 48px;
  text-align: center;
  color: $gray-color;

  .loader {
    border: 2px solid rgba($primary-color, 0.1);
    border-top: 2px solid $primary-color;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 0.8s linear infinite;
    margin: 0 auto 12px;
  }
}

.noData {
  padding: 48px;
  text-align: center;
  color: $gray-color;
  font-size: 13px;
}

.errorMessage {
  padding: 48px;
  text-align: center;
  color: #EF4444;

  p {
    margin-bottom: 16px;
    line-height: 1.5;
    font-size: 13px;
  }
}

.retryButton {
  padding: 8px 16px;
  background-color: $white;
  border: 1px solid #EF4444;
  color: #EF4444;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: #EF4444;
    color: $white;
  }

  &:active {
    transform: scale(0.98);
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.campaignRow {
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  &.expanded {
    background-color: rgba(0, 0, 0, 0.08);
  }
}

.setRow {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.02);
  &:hover {
    background-color: rgba(0, 0, 0, 0.06);
  }
  &.expanded {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.subTable {
  width: 100%;
  border-collapse: collapse;
  margin: 8px 0;
  background-color: white;

  th, td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
    font-size: 13px;
  }

  th {
    background-color: #f5f5f5;
    font-weight: 600;
  }

  tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
}

.expandedCell {
  padding: 0 !important;
  background-color: #f5f5f5;
}

.buttonContainer {
  display: flex;
  gap: 16px;
  align-items: flex-end;
}

.syncInfo {
  display: flex;
  align-items: center;
  gap: 8px;
}

.syncBtn {
  height: 36px;
  padding: 0 20px;
  background-color: #2e7d32;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.2s;
  min-width: 80px;

  &:hover:not(:disabled) {
    background-color: darken(#2e7d32, 5%);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &.syncing {
    .syncIcon {
      animation: spin 1s linear infinite;
    }
  }
}

.syncStatus {
  display: flex;
  align-items: center;
  gap: 16px;

  span {
    font-size: 13px;
    
    &.syncSuccess {
      color: #2e7d32;
    }
    
    &.syncError {
      color: #d32f2f;
    }
  }

  .syncTime {
    color: #6B7280;
    opacity: 0.7;
  }
}

.budgetControls {
  display: flex;
  align-items: center;
  gap: 8px;
}

.budgetInput {
  height: 32px;
  padding: 0 12px;
  border: 1px solid $border-color;
  border-radius: 4px;
  font-size: 13px;
  width: 120px;
  color: $text-color;

  &:focus {
    border-color: $primary-color;
    outline: none;
    box-shadow: 0 0 0 2px rgba($primary-color, 0.1);
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.applyButton {
  padding: 6px 16px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;
  background-color: $success-color;
  color: $white;
  border: none;
  min-width: 80px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: darken($success-color, 5%);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &.loading {
    position: relative;
  }
}

.buttonLoadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.buttonLoader {
  width: 16px;
  height: 16px;
  border: 2px solid rgba($primary-color, 0.1);
  border-top: 2px solid $primary-color;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

.statusCell {
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font-size: 13px;
    color: $text-color;
  }
}

.statusSwitch {
  cursor: pointer !important;
  vertical-align: middle;
  margin-right: 4px;
}