.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }
}

.closeButton {
  padding: 4px;
  background: none;
  border: none;
  cursor: pointer;
  color: #6B7280;
  line-height: 0;
  
  svg {
    width: 16px;
    height: 16px;
  }
}

.modalBody {
  margin-bottom: 20px;

  p {
    margin: 0;
    color: #4B5563;
    line-height: 1.5;

    strong {
      color: #111827;
    }
  }
}

.description {
  margin-top: 8px !important;
  font-size: 12px;
  color: #6B7280 !important;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.cancelButton {
  padding: 6px 12px;
  border: 1px solid #D1D5DB;
  border-radius: 4px;
  background-color: white;
  color: #374151;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    background-color: #F3F4F6;
  }
}

.deleteButton {
  padding: 6px 12px;
  border: 1px solid #DC2626;
  border-radius: 4px;
  background-color: #DC2626;
  color: white;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    background-color: #B91C1C;
  }
} 