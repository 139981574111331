.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); // 딤드 처리
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupContent {
  background-color: white;
  border-radius: 8px;
  padding: 0;
  width: 90%;
  max-width: 500px;
  max-height: 80vh; // 뷰포트 높이의 80%로 제한
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 0 auto; // 중앙 정렬
}

.popupHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #f1f1f1; // 헤더에 배경색 추가
  border-bottom: 2px solid #ddd; // 본문과의 구분을 위해 두꺼운 구분선 추가
}

.popupScroll {
  overflow-y: auto;
  padding: 20px;
  border: 1px solid #ddd; // 본문 테두리
  border-radius: 4px;
  text-align: left;
  background-color: white;
  font-size: 14px;
  line-height: 1.6;
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

h2 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

p {
  margin-top: 0;
  line-height: 1.6; // 본문 가독성을 위한 줄 간격
  font-size: 14px;
  color: #555; // 본문 글자색 변경
}

.pageContainer {
  padding: 24px 32px;
  background-color: #f5f6fa;
  min-height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  justify-content: center;

  .content {
    max-width: 800px; // 페이지 모드에서만 최대 너비 제한
  }
}

.content {
  background-color: white;
  border-radius: 8px;
  width: 100%; // 100%로 변경
  height: 100%; // 높이 추가
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden; // 오버플로우 처리
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #f1f1f1;
  border-bottom: 2px solid #ddd;
}

.body {
  flex: 1; // 남은 공간 채우기
  overflow: auto; // 스크롤 가능하도록
  padding: 20px;
  text-align: left;
  font-size: 14px;
  line-height: 1.6;
}
