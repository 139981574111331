// Variables
$primary-color: #1a237e;
$secondary-color: #283593;
$background-color: #f5f6fa;
$border-color: #e0e0e0;
$text-color: #2c3e50;
$white: #ffffff;
$error-color: #d32f2f;
$success-color: #2e7d32;

// Mixins
@mixin section-container {
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  padding: 24px;
  margin-bottom: 24px;
  width: 100%;
  min-width: 1140px;
}

@mixin button-base {
  padding: 0 16px;
  border: none;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: $primary-color;
  color: $white;
  height: 32px;

  &:hover:not(:disabled) {
    background-color: $secondary-color;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

@mixin input-base {
  padding: 0 12px;
  border: 1px solid $border-color;
  border-radius: 4px;
  font-size: 13px;
  color: $text-color;
  background-color: $white;
  transition: all 0.2s ease;
  height: 32px;

  &:focus {
    border-color: $primary-color;
    outline: none;
    box-shadow: 0 0 0 2px rgba($primary-color, 0.1);
  }

  &::placeholder {
    color: #9e9e9e;
  }
}

// Container
.container {
  padding: 24px 32px;
  background-color: $background-color;
  min-height: calc(100vh - 60px);  // 네비게이션 바 높이 고려
  min-width: 1200px;
  width: 100%;
  overflow-x: auto;
}

// Page Header
.pageHeader {
  margin-bottom: 32px;
  width: 100%;
  min-width: 1140px;

  h1 {
    font-size: 28px;
    font-weight: 600;
    color: $primary-color;
    margin-bottom: 8px;
  }

  .pageDescription {
    font-size: 16px;
    color: #546e7a;
  }
}

// Upload Section
.uploadSection {
  @include section-container;
}

.uploadGroup {
  display: flex;
  align-items: flex-end;
  gap: 32px;

  .uploadItem {
    width: 360px;
  }

  .uploadDateItem {
    width: 200px;
  }

  .uploadButtonContainer {
    width: 100px;
    margin-left: -60px;
  }
}

.uploadItem, .uploadDateItem {
  label {
    display: block;
    margin-bottom: 8px;
    font-size: 13px;
    font-weight: 500;
    color: $text-color;
  }
}

.fileInputWrapper {
  position: relative;
  
  input[type="file"] {
    @include input-base;
    width: 100%;
    box-sizing: border-box;
    padding: 0 12px;
  }
}

// Search Section
.searchSection {
  @include section-container;
}

.searchControls {
  display: flex;
  align-items: flex-end;
  gap: 32px;
}

.datePicker {
  display: flex;
  align-items: flex-end;
  gap: 24px;
  width: 400px;
}

.datePickerItem {
  width: 180px;
  
  label {
    display: block;
    margin-bottom: 8px;
    font-size: 13px;
    font-weight: 500;
    color: $text-color;
  }
}

.customDatePicker {
  @include input-base;
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;
  
  :global {
    .react-datepicker__day-contents {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.mediaFilter {
  width: 220px;

  label {
    display: block;
    margin-bottom: 8px;
    font-size: 13px;
    font-weight: 500;
    color: $text-color;
  }
}

.searchInput {
  @include input-base;
  width: 100%;
}

.searchButtonContainer {
  width: 80px;
}

// Buttons
.uploadBtn, .searchBtn {
  @include button-base;
  width: 100%;
}

.uploadBtn {
  @include button-base;
  width: 100%;
  position: relative;
  min-width: 80px;
  height: 32px;
  
  &.loading {
    opacity: 0.7;
    cursor: not-allowed;
    color: transparent;
  }
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #1877f2;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

// Data Section
.dataSection {
  @include section-container;
}

.dataInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  .totalCount {
    font-size: 13px;
    color: #546e7a;
  }

  .loadingWrapper {
    display: flex;
    align-items: center;
    margin-left: 16px;
  }
}

// Table Styles
.tableWrapper {
  margin: 16px 0;
  overflow-x: auto;
}

.table {
  width: 100%;
  min-width: 1140px;
  border-collapse: separate;
  border-spacing: 0;

  th {
    background-color: $primary-color;
    color: $white;
    padding: 12px 16px;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    position: sticky;
    top: 0;
    font-size: 13px;

    &:first-child {
      border-top-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
    }

    .sortIcon {
      margin-left: 4px;
      opacity: 0.7;
    }
  }

  td {
    padding: 12px 16px;
    border-bottom: 1px solid $border-color;
    color: $text-color;
    background-color: $white;
    white-space: nowrap;
    font-size: 13px;
  }

  tbody tr {
    transition: background-color 0.2s ease;

    &:hover {
      background-color: rgba($primary-color, 0.02);
    }
  }
}

// Pagination
.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $border-color;
}

.paginationControls {
  display: flex;
  align-items: center;
  gap: 8px;
}

.paginationButton {
  @include button-base;
  background-color: $white;
  border: 1px solid $border-color;
  color: $text-color;
  min-width: 80px;

  &:hover:not(:disabled) {
    background-color: $background-color;
    border-color: $primary-color;
    color: $primary-color;
  }
}

.pageInfo {
  margin: 0 16px;
  font-size: 13px;
  color: $text-color;
}

.pageJump {
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font-size: 13px;
    color: $text-color;
  }

  input {
    @include input-base;
    width: 80px;
    text-align: center;
  }
}

.pageSizeSelect {
  @include input-base;
  width: 120px;
}

.dayContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1px;
  
  span {
    margin-bottom: -2px;
  }
}

.dataIndicator {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-top: -1px;
}

.hasData {
  background-color: $success-color;
}

.noData {
  background-color: $error-color;
}

.buttonContainer {
  display: flex;
  gap: 16px;
  align-items: flex-end;
}

.uploadBtn {
  @include button-base;
  width: 100px;
}

.syncInfo {
  display: flex;
  align-items: center;
  gap: 8px;
}

.syncBtn {
  @include button-base;
  width: 100px;
  background-color: $success-color;

  &:hover:not(:disabled) {
    background-color: darken($success-color, 5%);
  }

  &.syncing {
    .syncIcon {
      animation: spin 1s linear infinite;
    }
  }
}

.syncStatus {
  display: flex;
  align-items: center;
  gap: 16px;

  span {
    font-size: 13px;
    
    &.syncSuccess {
      color: $success-color;
    }
    
    &.syncError {
      color: $error-color;
    }
  }

  .syncTime {
    color: $text-color;
    opacity: 0.7;
  }
}