.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 320px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  h2 {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #111827;
  }
}

.closeButton {
  padding: 4px;
  background: none;
  border: none;
  cursor: pointer;
  color: #6B7280;
  line-height: 0;
  
  svg {
    width: 16px;
    height: 16px;
  }
}

.formGroup {
  margin-bottom: 8px;
  padding: 0 4px;

  label {
    display: block;
    font-size: 12px;
    font-weight: 500;
    color: #374151;
    margin-bottom: 4px;
  }

  input, select {
    width: 260px;
    height: 26px;
    padding: 0 8px;
    font-size: 12px;
    border: 1px solid #D1D5DB;
    border-radius: 4px;
    background-color: white;
    
    &:focus {
      outline: none;
      border-color: #2563EB;
    }
  }

  select {
    appearance: none;
    padding-right: 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 12px;
  }
}

.error {
  font-size: 11px;
  color: #DC2626;
  margin: 8px 0;
  padding: 6px 8px;
  background-color: #FEF2F2;
  border-radius: 4px;
  border: 1px solid #FCA5A5;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 6px;
  margin-top: 16px;
}

.cancelButton, .submitButton {
  height: 26px;
  padding: 0 12px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
}

.cancelButton {
  background-color: white;
  border: 1px solid #D1D5DB;
  color: #374151;
  
  &:hover {
    background-color: #F3F4F6;
  }
}

.submitButton {
  background-color: #2563EB;
  border: 1px solid #2563EB;
  color: white;
  
  &:hover {
    background-color: #1D4ED8;
  }

  &:disabled {
    background-color: #93C5FD;
    border-color: #93C5FD;
    cursor: not-allowed;
  }
}

.labelWithInfo {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
}

.infoIcon {
  display: flex;
  align-items: center;
  color: #6B7280;
  cursor: help;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    color: #374151;
  }
}

.infoMessage {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 12px;
  padding: 8px 10px;
  background-color: #F3F4F6;
  border-radius: 4px;
  font-size: 11px;
  color: #4B5563;

  svg {
    width: 14px;
    height: 14px;
    color: #6B7280;
  }
}

.tooltip {
  font-size: 12px !important;
  max-width: 200px !important;
  padding: 8px 12px !important;
  line-height: 1.4 !important;
  z-index: 1100 !important;
} 